footer {
  grid-area: footer;
  width: auto;
  height: 5vh;
  background-color: #ffffff;
  color: #000 !important;
  box-shadow: 1px -4px 5px 0px RGB(0 0 0 / 58%);
  text-align: center;
  font-family: "Regular", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 11px;
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

@media (max-width: 780px) {
  footer {
    font-size: 9px;
  }
}
