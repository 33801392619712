.title {
  font-size: 16px;
  color: var(--background-color-Dark-button);
  font-weight: 600;
  font-family: "Regular", Helvetica, Arial, Verdana, Tahoma, sans-serif;

  &:hover {
    text-decoration: none;
    color: var(--background-color-light-button);
  }
}
