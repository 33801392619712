body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fbfbfb;
  scroll-behavior: smooth;
}
.App {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template:
    "nav  article " 1fr
    "nav  footer " auto /
    auto 1fr auto;
}

article {
  grid-area: article;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  height: 95vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

nav {
  grid-area: nav;

  width: auto;
  height: 100vh;
  z-index: 1000;
}

span {
  a {
    display: none !important;
  }
  .logout {
    display: none !important;
  }
}
